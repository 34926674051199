import request from '@/services/request'
import apis from '@/services/apis'
import moment from "moment";

export const getAvailableBarges = (parmas) => {
  return request.get({
    url: `${apis.getAvailableBarges}`,
    params: parmas
  })
}
export const getJobInfoByOrderId = (orderId) => {
  return request.get({
    url: `${apis.getJobInfoByOrder}?orderId=${orderId}`
  })
}

export const getAuditTrailsList = (jobId, pageSize = 20, pageNumber = 0) => {
  return request.get({
    url: `/ebdn/jobs/${jobId}/audit_trails?pageSize=${pageSize}&pageNumber=${pageNumber}`
  })
}

export const getJobFiles = (jobId) => {
  return request.get({
    url: `/ebdn/jobs/${jobId}/files`
  })
}

export const getJobReceipients = (jobId) => {
  return request.get({
    url: `/ebdn/jobs/${jobId}/recipients`
  })
}

export const saveJobReceipients = (jobId, payload) => {
  return request.post({
    url: `/ebdn/jobs/${jobId}/recipients`,
    data: payload
  })
}

export const getRecipients = (jobId) => {
  return request.get({
    url: `/ebdn/jobs/${jobId}/recipient`
  })
}

export const addRecipients = (jobId, payload) => {
  return request.post({
    url: `/ebdn/jobs/${jobId}/recipient`,
    data: payload
  })
}

export const shareEbdnEmail = (jobId, payload) => {
  return request.put({
    url: `/ebdn/jobs/${jobId}/shareEbdnEmail`,
    data: payload
  })
}

export const updateReferenceNo = (jobId, payload) => {
  return request.post({
    url: `/ebdn/jobs/${jobId}/updateReferenceNo`,
    data: payload
  })
}

export const getSageTrackOrderList = (jobId) => {
  return request.get({
    url: `/victory/trackOrder/${jobId}`
  })
}

export const submitEbdnToSage = (jobId) => {
  const data = { jobId }
  return request.post({
    url: `/victory/processOrder`,
    data,
  })
}

export const downloadJobExcel = () => {
  const data = null
  return request.getFile({
    url: apis.baseUrl + apis.downloadJobExcel, data
  }).then(res => {
    const filename = "upload-job-" + moment().format("yyyyMMDD") + ".xlsx"
    downloadFile(res, filename)
  })  
}

export function downloadFile( res, filename ) {
  filename = filename ?? 'Download.txt'
  const url = window.URL.createObjectURL(new Blob([res], { type: res.type }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
